<template>
  <div class="worship-smile-voice">
    <div class="smile-voice-title">
      <h4>相册</h4>
      <button type="button">增加</button>
      <img class="border-bot-right" src="../../../assets/images/news/news-border-bot-right.png">
    </div>
    <div class="smile-voice-list">
      <div class="list-item" v-for="index in 10">
        <img src="../../../assets/images/news/group-list-avter.png">
      </div>
    </div>
    <div class="smile-voice-more">
      <span>查看更多(10张)</span>
      <img class="more-img" src="../../../assets/images/hall/worship/biography-border-logo.png">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'worship-smile-voice',
    mounted() {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
.worship-smile-voice {
  position: relative;
  z-index: 22;
  min-height: 608px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0 20px;
  .smile-voice-more {
    width: 100%;
    background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
    border-radius: 29px;
    padding: 20px 21px 18px;
    position: relative;
    span {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .more-img {
      position: absolute;
      right: 0;
      top: -12px;
    }
  }
  .smile-voice-list {
    @include w-flex;
    flex-wrap: wrap;
    padding: 22px 14px;
    justify-content: space-between;
    .list-item {
      width: 18.8%;
      margin-bottom: 20px;
      height: 170px;
      img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }
  .smile-voice-title {
    position: relative;
    @include w-flex;
    @include align-items;
    justify-content: space-between;
    border-bottom: 1px solid #54A5D2;
    padding: 10px 0;
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: #353535;
    }
    button {
      width: 150px;
      height: 50px;
      background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
      box-shadow: -2px 2px 5px 0px rgba(101, 101, 100, 0.5);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      border: none;
      margin-right: 60px;
    }
    .border-bot-right {
      position: absolute;
      right: 0;
      z-index: 22;
      bottom: 0;
    }
  }
}
</style>
